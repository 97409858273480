

thWeather.config({
    apiUrl: localizeParams.template_path + 'components/OverlayMeteo/ajax/openweathermap.php',
    soir: '21:00:00',
    nuit: '03:00:00',
});

moment.locale(localizeParams.currentlanguage);

function initMeteo() {
    const dayParts = ['matin', 'aprem'];
    let days = [0, 1, 2];
    const keep_only_current_moment = false;

// gestion de celui qui est visible directement dans le menu principal
    let topweather = false;

    document.querySelectorAll('[data-city]').forEach(function (elCity) {
        var _self = $(elCity);

        const city = elCity.dataset.city;

        const spe_day = elCity.dataset.day;
        if (typeof spe_day != 'undefined') {
            days = [spe_day];
        }

        days.forEach(function (day, k) {
            let maxTemp = 0;
            let minTemp = 1000;
            let removeChecker = [];
            let already_res_for_day = false;

            dayParts.forEach(function (dayPart, k) {

                //Données aux différents moments de la journée
                thWeather.get({city: city, moment: dayPart, day: day, lang: localizeParams.currentlanguage}, function (data) {
                    if (
                        typeof data !== "undefined"
                        && data !== null
                        && data
                        && (!keep_only_current_moment || (keep_only_current_moment && !already_res_for_day))
                    ) {
                        var date = moment(data.dt * 1000).format('dddd D MMM');

                        maxTemp = (data.temp_max > maxTemp) ? data.temp_max : maxTemp;
                        minTemp = (data.temp_min < minTemp) ? data.temp_min : minTemp;

                        const windspeed = Math.round((data.wind.speed * 3.6));

                        //elCity.querySelector('#day-' + day + ' .jour-titre').textContent = date;

                        elCity.querySelector('#day-' + day + ' .' + dayPart + ' .temp.min').textContent = data.temp_min + "°C";
                        // elCity.querySelector('#day-' + day + ' .' + dayPart + ' .temp.max').textContent = data.temp_max + "°max";
                        // elCity.querySelector('#day-' + day + ' .' + dayPart + ' .temp.default').textContent = data.degrees + "°default";

                        elCity.querySelector('#day-' + day + ' .' + dayPart + ' .data-icon').innerHTML = data.iconHtml;

                        elCity.querySelector('#day-' + day + ' .' + dayPart + ' .vent .speed').textContent = windspeed;
                        elCity.querySelector('#day-' + day + ' .' + dayPart + ' .vent .direction').textContent = localizeParams.windDirectionTranslation[data.winddirection.direction];

                        if (!topweather) {
                            thWeather.get({city: city, moment: 'current', day: day, lang: localizeParams.currentlanguage}, function (datacurrent) {
                                if (
                                    typeof datacurrent !== "undefined"
                                    && datacurrent !== null
                                    && datacurrent
                                ) {
                                    $('.data-topweather-weather.weather .data-icon').html(datacurrent.iconHtml);
                                    $('.data-topweather-weather.weather .temp').html(datacurrent.degrees + "°");
                                    $('.data-topweather-weather.weather').attr('data-hover', datacurrent.weather[0].description);
                                    $('.data-topweather-weather.wind .windspeed').html(windspeed);
                                    $('.data-topweather-weather.wind .winddirection').text(localizeParams.windDirectionTranslation[data.winddirection.direction]);
                                    $('.data-topweather-weather.wind .icon-itineraire').addClass('rotate-' + data.winddirection.deg);
                                    topweather = true;
                                }
                            });
                        }
                        already_res_for_day = true;
                        // pas ou plus de données pour cette partie du jour -> masque l'élément
                    } else {
                        //console.log('remove');
                        elCity.querySelector('#day-' + day + ' .' + dayPart).textContent = '';
                        //elCity.querySelector('#day-' + day).remove();
                        removeChecker.push(true);
                    }
                });

                if (removeChecker.length >= 4) {
                    //$("#day-" + day, _self).remove();
                }
            });
        });
    });
}

initMeteo();