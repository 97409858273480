(function () {

    // Init des blocs
    const blocs = document.querySelectorAll('.bloc-relation');
    blocs.forEach(bloc => {

        // Init des sliders
        const sliderDirection = bloc.classList.contains('--direction-rtl') ? 'rtl' : 'ltr';
        const sliders = bloc.querySelectorAll('.js-slider-relation');
        sliders.forEach(slider => {
            const splide = new Splide(slider, {
                gap: 40,        // gap en JS plutôt que margin en css pour gérer plus facilement l'option 'rtl'
                direction: sliderDirection,

                breakpoints: {
                    768: {
                        arrows: false,
                        direction: 'ltr'       // direction toujours de gauche à droite en mobile
                    },
                    600: {
                        gap: 20
                    },
                }
            }).mount();
        });

        // Init des select -> redirection vers une page à la sélection
        const selectNodes= document.querySelectorAll('.js-select-relation');
        selectNodes.forEach(selectNode => {
            // repassage en jQuery coz lib selectric...
            $(selectNode).change(function(e){
                window.location.href = selectNode.options[selectNode.selectedIndex].value;
            });
        });
    });


})();
