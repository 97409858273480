// JS qui concerne les single SIT
(function () {

    if (document.body.classList.contains('single-sit')) {

        // Init des sliders de la page
        // ==========================================================================

        // Init du slider des horaires
        let sliders = document.querySelectorAll('.js-slider-sit-horaires');
        sliders.forEach(slider => {
            const splide = new Splide(slider).mount();
        });

        // Init du slider du bloc relation
        sliders = document.querySelectorAll('.js-slider-sit-relation');
        sliders.forEach(slider => {
            const splide = new Splide(slider, {
                breakpoints: {
                    600: {
                        arrows: false,
                    },
                }
            }).mount();
        });


        // Boutons "Voir plus", quand texte tronqué sur les blocs "À propos"
        // ==========================================================================
        const blocViewMore = document.querySelectorAll('.js-view-more');        /* à changer en single-sit sur un vrai projet */
        blocViewMore.forEach(item => {
            const content = item.querySelector('[data-view-more-content]');
            const trigger = item.querySelector('[data-view-more-trigger]');

            // Comportement de viewmore sur le bloc si sa hauteur est suffisante
            if (content && content.getBoundingClientRect().height >= 220) {
                item.classList.add('view-more-active');

                trigger.addEventListener('click', function () {
                    item.classList.toggle('is-expanded');
                });
            }
        });


        // Bouton dans la barre sticky pour scroller jusqu'au bloc "Carte"
        // ==========================================================================
        const btnMapAnchor = document.querySelector('.js-map-anchor');
        if (btnMapAnchor) {
            btnMapAnchor.addEventListener('click', function (e) {
                e.preventDefault();
                const hash = this.hash;
                const target = document.querySelector(hash);
                const header = document.querySelector('.js-barre-navigation');

                // Y offset pour le sticky header
                const offset = (header) ? header.offsetHeight : 0;
                const targetTop = window.scrollY + target.getBoundingClientRect().top - offset - 20;

                $('html, body').animate({
                    scrollTop: targetTop
                }, 800, function () {
                    window.location.hash = hash;
                });
            });
        }


        if ($('.js-denivele').length > 0) {
            // Chart.js, lourd (170ko) mais beaucoup d'option dont des interractions
            // https://www.chartjs.org/

            //Récupération de la couleur de la page, et rajout de 80 à la fin du #hexa pour mettre la transparent de 50% pour le fill
            var chartColors = "#105540";
            var bgChartCurve = "#2D7154";

            //Initialisation du graph avec les valeurs en DATA sur l'élément canvas généré en PHP en fonction des informations fournis dans le KML
            var YAxis = $('.js-denivele').attr('data-YAxis').split(',');
            //Axe des ordonnées minimum à 100
            //YAxis.push(100);
            var XAxis = $('.js-denivele').attr('data-XAxis').split(',');
            var ctx = $('.js-denivele');
            var myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: XAxis,
                    datasets: [
                        {
                            label: 'DÉNIVELÉ (m)',
                            data: YAxis,
                            borderColor: bgChartCurve,
                            fill: true,
                            backgroundColor: (context) => {
                                const bgColor = [
                                    '#3A795F',
                                    '#A3C1B5',
                                    '#E4ECE8',
                                ];
                                if (!context.chart.chartArea) {
                                    return;
                                }
                                const {ctx, data, chartArea: {top, bottom}} = context.chart;
                                const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
                                gradientBg.addColorStop(0, bgColor[0]);
                                gradientBg.addColorStop(0.5, bgColor[1]);
                                gradientBg.addColorStop(1, bgColor[2]);
                                return gradientBg;
                            },
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            pointHitRadius: 10,
                            pointBackgroundColor: "#103A2E",
                            pointBorderColor: "#002C2E",
                            pointBorderWidth: 2,
                            lineTension: 0.1,
                        }
                    ]
                },

                hover: {
                    mode: 'index'
                },
                options: {
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            display: true,
                            ticks: {
                                //beginAtZero: true, // #18268
                                fontFamily: "Lexend",
                                fontSize: 10,
                                fontColor: "#000",
                                maxTicksLimit: 20
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Distance (km)',
                                fontFamily: 'Lexend',
                                fontStyle: "bold",
                                fontSize: 10,
                                fontColor: "#000",
                                padding: {
                                    bottom: 10,
                                    top: 10
                                }
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }],
                        yAxes: [{
                            display: true,
                            ticks: {
                                //beginAtZero: true, // #18268
                                stepSize: (Math.abs(YAxis[0]) + YAxis[YAxis.length - 1]) / 2,
                                fontFamily: "Lexend",
                                fontSize: 10,
                                fontColor: "#000",
                                maxTicksLimit: 4
                            },
                            gridLines: {
                                drawOnChartArea: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Altitude (m)',
                                fontFamily: "Lexend",
                                fontStyle: "bold",
                                fontSize: 10,
                                fontColor: "#000",
                                padding: {
                                    bottom: 10,
                                    top: 10
                                }
                            }
                        }],
                    },
                    tooltips: {
                        bodyFontFamily: 'Lexend',
                        titleFontFamily: 'Lexend',
                        displayColors: false,
                    }
                },
            });

            document.querySelector(".js-denivele").onmousemove = function (evt) {
                var activePoint = myChart.getElementAtEvent(event);

                // make sure click was on an actual point
                if (activePoint.length > 0) {
                    $('[data-markerindex=' + activePoint[0]._index + ']').addClass('active');
                } else {
                    $('[data-markerindex]').removeClass('active');
                }
            };
        }

    }


})();

