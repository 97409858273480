// Scripts pour ajouter ou supprimer la classe "no-scroll"
function addNoScroll() {
    if (!document.body.classList.contains("no-scroll")) {
        document.body.classList.add("no-scroll")
    }
}

function removeNoScroll() {
    if (document.body.classList.contains("no-scroll") && !$(th_overlay.selector_overlay_fiche_sit).hasClass('open')) {
        document.body.classList.remove("no-scroll");
    }
}

th_overlay.addCallbackOpen(addNoScroll);
th_overlay.addCallbackClose(removeNoScroll);



// Remonte la barre de navigation par dessus le megamenu (et l'overlay de recherche)
function onMegaMenuOpen(overlayId) {
    if (overlayId === 'overlay-menu' || overlayId === 'overlay-search') {
        document.body.classList.add('has-mega-menu-open');

        const btnMenu = document.querySelector('.barre-navigation .btn-menu');
        btnMenu.querySelector('.label').innerText = 'Fermer';
    }
}

function onMegaMenuClose(overlayId) {
    if (overlayId === 'overlay-menu' || overlayId === 'overlay-search') {
        document.body.classList.remove('has-mega-menu-open');

        const btnMenu = document.querySelector('.barre-navigation .btn-menu');
        btnMenu.querySelector('.label').innerText = 'Menu';
    }
}

th_overlay.addCallbackOpen(onMegaMenuOpen);
th_overlay.addCallbackClose(onMegaMenuClose);