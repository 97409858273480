(function () {

    // Init des sliders
    const blocs = document.querySelectorAll('.js-bloc-slider-saisons');
    blocs.forEach(bloc => {
        // Controleur du slider principal
        const sliderMonths = bloc.querySelector('.js-slider-months');
        const splideNav = new Splide(sliderMonths, {

            // focus : 'center',
            // trimSpace: false,
            type: 'loop',
            isNavigation: true,
            speed: 700,
            easing: 'ease-in-out',
            drag: false,

            breakpoints: {
                600: {
                    speed: 400,
                },
            }
        });

        // Slider principal avec les contenus
        const sliderMain = bloc.querySelector('.js-slider-images');
        const splideMain = new Splide(sliderMain, {
            type: 'loop',
            arrows: false,
            type: 'fade',
            drag: false,
            trimSpace: false,
            speed: 700,
            easing: 'ease-in-out',

            breakpoints: {
                600: {
                    speed: 400,
                },
            }
        });

        // Synchro des sliders avant la méthode mount()
        splideMain.sync( splideNav );
        splideMain.mount();
        splideNav.mount();
    });

})();
