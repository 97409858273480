(function () {
    // Languages selector
    const langDropdownDesktop = document.querySelector('.js-lang-dropdown-desktop');
    if (langDropdownDesktop) {
        const trigger = langDropdownDesktop.querySelector('.lang-dropdown-desktop__btn');
        trigger.addEventListener('click', e => {
            e.preventDefault();
            langDropdownDesktop.classList.toggle('is-open');
        });
    }

    // Selectric est activé par défaut sur toutes les selects,
    // mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
    $('.js-lang-dropdown-mobile').selectric('destroy');

    // Ce script est nécessaire pour forcer le changement de page au click sur une option


    // Select sur la version mobile
    const selectMobile = document.querySelector('.js-lang-dropdown-mobile');
    if(selectMobile) {
        selectMobile.addEventListener('change', (e) => {
            window.location.href = selectMobile.options[selectMobile.selectedIndex].value;
        });
    }


    // Affichage de la box des notifications
    const btnsNotifs = document.querySelectorAll('.js-btn-notifs');
    btnsNotifs.forEach(btnNotifs => {
        const alertNotifs = btnNotifs.parentNode.querySelector('.js-alert-notifs');

        btnNotifs.addEventListener('click', e => {
            btnNotifs.classList.toggle('is-open');
            alertNotifs.classList.toggle('is-open');
        })
    });


    // Gestion du clic sur le bouton de menu de la barre de nav ET toolbar : ouverture ou fermeture
    const btnMenus = document.querySelectorAll('.btn-menu');
    const overlayMenu = document.getElementById('overlay-menu');
    const overlaySearch = document.getElementById('overlay-search');

    btnMenus.forEach(btnMenu => {
        btnMenu.addEventListener('click', e => {
            /*console.log('\n**** click' );
            console.log('>>> overlayMenu open : ', overlayMenu.classList.contains('open'));
            console.log('>>> overlaySearch open : ', overlaySearch.classList.contains('open'));*/

            // L'overlay de recherche est ouvert -> au clic, fermeture de l'overlay de recherche
            if (overlaySearch.classList.contains('open')) {
                th_overlay.close('overlay-search');
                console.log('close search' );
            // L'overlay de mega menu est ouvert -> au clic, fermeture de l'overlay de mega menu
            } else if (overlayMenu.classList.contains('open')) {
                th_overlay.close('overlay-menu');
                console.log('close menu' );
            // Aucun overlay d'ouvert -> au clic, ouverture de l'overlay de mega menu
            } else {
                th_overlay.open('overlay-menu');
                console.log('open menu' );
            }
        });
    });

})();