// déplacement du panier si il doit apparaitre à différents endroits du HTML
// possible uniquement avec des overlays car l'html du panier ne doit être présent qu'une seule fois dans le code sinon marche pas

th_overlay.addCallbackOpen(function (overlay) {
    if (overlay == "overlay-reservation") {
        setTimeout(function() {
            $("#widget-panier").appendTo(".js-panier-overlay-resa");
        });
    }
});

th_overlay.addCallbackClose(function (overlay) {
    if (overlay == "overlay-reservation") {
        setTimeout(function() {
            $("#widget-panier").appendTo(".js-panier-navbarr");
        });
    }
});