// ouverture de l'overlay des cards agenda sur le listingfma
function parseCardAgendaClickOverlay(){
    $('.js-listing-fma .card:not(.parsed)').on('click',function (e) {
        e.preventDefault();

        var elem = $(this);

        var sit_url = elem.attr('href')+'?overlay';
        var overlay_id = 'overlay-agenda';

        // @todo dev : dates à cabler
        var datetime = $('.dtstart',elem).attr('datetime');
        var date = moment($('.dtstart',elem).attr('datetime'),"YYYY-MM-DD").format("Do MMMM");
        var info_date = this.querySelector('.info-date');
        if(info_date) {
            var info_time = info_date.innerText;
        }

        $('#' + overlay_id + ' .js-overlay-content').html('');
        $('#' + overlay_id + ' .loader').removeClass('hide');

        $.get(sit_url, function (responseHTML) {
            $('#' + overlay_id + ' .loader').addClass('hide');
            $('#' + overlay_id + ' .js-overlay-content').html(responseHTML);
            $('#' + overlay_id + ' .js-overlay-content .time').html($('time',elem).html());
            $('#' + overlay_id + ' .js-see-event').attr('href',elem.attr('href'));
            $('#' + overlay_id + ' .js-datetime').attr('datetime',datetime);
            $('#' + overlay_id + ' .js-date-text').text(date);

            if (info_time) {
                $('#' + overlay_id + ' .js-date-hours').text(info_time);
            } else {
                $('#' + overlay_id + ' .js-date-hours').parent().remove();
            }
        });

        th_overlay.open(overlay_id, true, true, false);

    }).addClass('parsed');
}

parseCardAgendaClickOverlay();