
(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-ancres');
    sliders.forEach(function(slider) {
        const splide = new Splide(slider, {
            autoHeight: true,
            padding: { left: 40, right: 50 },
            gap: 40
        }).mount();

        // passe le slider à la lib des ancres si on a besoin de le synchroniser
        th_ancres.selectors.sliderAncre = splide;
    });

})();
