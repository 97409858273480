function initWidgetsAllianceReseaux() {
    const idPanier = 'eNR7eHg';
    const idIntegration = '1643';
    let langue = 'fr';

    switch (document.documentElement.getAttribute("lang")) {
        case "en-GB":
            langue = "uk";
            break;
        default:
            break;
    }

    const elems = [...document.querySelectorAll("[data-code-ui]")];

    if (elems.length > 0) {

        elems.forEach(el => {

            // WIDGET DANS LES PAGES
            let data = {
                idPanier: idPanier,
                idIntegration: idIntegration,
                langue: langue,
                ui: el.getAttribute("data-code-ui")
            };

            let id_elem = el.getAttribute("id");

            let widgetProduit = AllianceReseaux.Widget.Instance("Produit", data);

            if (document.location.hash) {
                let parts = document.location.hash.split('date=');
                if (parts.length > 1) {
                    var options = parts[1].split(',');

                    if (options.length === 3) {
                        const date = new Date(options[0] * 1000);

                        let day = (date.getDate());
                        if (day < 10) {
                            day = '0' + day;
                        }
                        let month = (date.getMonth() + 1);
                        if (month < 10) {
                            month = '0' + month;
                        }

                        var dateFormated = date.getFullYear() + '-' + month + '-' + day;
                        if (wp_debug) {
                            console.log('AUTO COMPLETE OPEN SYSTEM : ');
                            console.log('Date : ', dateFormated);
                            console.log('Durée : ', options[1]);
                            console.log('Nombre adulte : ', options[2]);
                            console.log('CODE UI : ', data.ui);
                        }

                        widgetProduit.PreApp("dispo.debut", dateFormated);
                        widgetProduit.PreApp("dispo.duree", parseInt(options[1]));
                        widgetProduit.PreApp("dispo.nbAdultes", parseInt(options[2]));
                        widgetProduit.PreApp("dispo.rechercher", true);
                    }
                }
            }

            // Widget ADDOCK
            let produitsAddock = el.getAttribute("data-produits-addock");
            if (produitsAddock) {
                produitsAddock = produitsAddock.split(",");

                widgetProduit.PreApp("produit.addock.produits", produitsAddock);
            }
            widgetProduit.PreApp('cible', id_elem);
            widgetProduit.Initialise();
        });
    }

    // PANIER
    const widgetPanier = AllianceReseaux.Widget.Instance("Panier", {
        idPanier: idPanier,
        idIntegration: idIntegration,
        langue: langue
    });

    widgetPanier.Initialise();
}


function updatePanierIcon() {
    $('#widget-panier .EnteteNbArticles').text($('#widget-panier .EnteteNbArticles').text().replace('(', '').replace(')', ''));
}

function checkIfAllianceInit() {
    if (typeof AllianceReseaux != 'undefined') {
        setTimeout(initWidgetsAllianceReseaux, 100);
    } else {
        console.log("ALLIANCE INIT")
        setTimeout(checkIfAllianceInit, 100);
    }
}

// Script pour focus le champ recherche à l'ouverture de l'overlay
th_overlay.addCallbackOpen(overlayId => {
    if (overlayId === "overlay-opensystem-reserver" || overlayId === "overlay-opensystem-offrir") {
        checkIfAllianceInit();
    }
})